.App-header {
    display: flex;
    flex-direction: row;
    padding: 0.5em;
    align-items: center;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
}

.App-header-logo {
}

.App-header-info {
    flex: 0.9;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.App-header p {
    font-size: 10px;
    text-align: center;
}

.App-header img {
    height: 80px;
}

.Form-info {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.Form-info p, h1 {
    margin: 2px;
}

.Form-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Form-container {
    display: flex;
    flex-direction: column;
    padding: 2em 5em;
    align-items: center;
    flex: 1;
}

@media(max-width: 500px) {
    .Form-container {
        display: flex;
        flex-direction: column;
        padding: 10px;
        align-items: center;
        flex: 1;
    }
}

.Form-buttons {
    flex: 1;
    display: flex;
    margin-top: 3em;
}

.resp-container {
    margin: auto;
    align-items: center;
    align-content: center;
    position: relative;
    overflow: hidden;
    width: 100%;
    min-height: 500px;
    /* max-height: 700px; */
    padding-top: 3%;
}

.resp-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border: 0;
}

@media (max-width: 500px) {
    .title-resp {
        font-size: 9px;
        flex: 0.6!important;
    }

    .title2-resp {
        font-size: 9px;
        flex: 0.4!important;
        word-break: break-all!important;
    }

    .content-resp {
        flex-wrap: wrap;
    }
}
