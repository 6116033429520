body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.__point {
    cursor: pointer;
}

.App-header {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    padding: 0.5em;
    -webkit-align-items: center;
            align-items: center;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
}

.App-header-logo {
}

.App-header-info {
    -webkit-flex: 0.9 1;
            flex: 0.9 1;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.App-header p {
    font-size: 10px;
    text-align: center;
}

.App-header img {
    height: 80px;
}

.Form-info {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.Form-info p, h1 {
    margin: 2px;
}

.Form-content {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.Form-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 2em 5em;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex: 1 1;
            flex: 1 1;
}

@media(max-width: 500px) {
    .Form-container {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        padding: 10px;
        -webkit-align-items: center;
                align-items: center;
        -webkit-flex: 1 1;
                flex: 1 1;
    }
}

.Form-buttons {
    -webkit-flex: 1 1;
            flex: 1 1;
    display: -webkit-flex;
    display: flex;
    margin-top: 3em;
}

.resp-container {
    margin: auto;
    -webkit-align-items: center;
            align-items: center;
    -webkit-align-content: center;
            align-content: center;
    position: relative;
    overflow: hidden;
    width: 100%;
    min-height: 500px;
    /* max-height: 700px; */
    padding-top: 3%;
}

.resp-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border: 0;
}

@media (max-width: 500px) {
    .title-resp {
        font-size: 9px;
        -webkit-flex: 0.6 1!important;
                flex: 0.6 1!important;
    }

    .title2-resp {
        font-size: 9px;
        -webkit-flex: 0.4 1!important;
                flex: 0.4 1!important;
        word-break: break-all!important;
    }

    .content-resp {
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
    }
}

/* Reset -------------------------------------------------------------------- */
* 	 { margin: 0;padding: 0; }
body { font-size: 14px; }

/* OPPS --------------------------------------------------------------------- */

h3 {
	margin-bottom: 10px;
	font-size: 15px;
	font-weight: 600;
	text-transform: uppercase;
}

.opps {
	width: 496px;
	border-radius: 4px;
	box-sizing: border-box;
	padding: 0 45px;
	margin: 0 auto;
	overflow: hidden;
	border: 1px solid #b0afb5;
	font-family: 'Open Sans', sans-serif;
	color: #4f5365;
}

.opps-reminder {
	position: relative;
	top: -1px;
	padding: 9px 0 10px;
	font-size: 11px;
	text-transform: uppercase;
	text-align: center;
	color: #ffffff;
	background: #000000;
}

.opps-info {
	margin-top: 26px;
	position: relative;
}

.opps-info:after {
	visibility: hidden;
     display: block;
     font-size: 0;
     content: " ";
     clear: both;
     height: 0;

}

.opps-brand {
	width: 45%;
	float: left;
}

.opps-brand img {
	max-width: 150px;
	margin-top: 2px;
}

.opps-ammount {
	width: 55%;
	float: right;
}

.opps-ammount h2 {
	font-size: 36px;
	color: #000000;
	line-height: 24px;
	margin-bottom: 15px;
}

.opps-ammount h2 sup {
	font-size: 16px;
	position: relative;
	top: -2px
}

.opps-ammount p {
	font-size: 10px;
	line-height: 14px;
}

.opps-reference {
	margin-top: 14px;
}

.reference-number {
	font-size: 27px;
	color: #000000;
	text-align: center;
	margin-top: -1px;
	padding: 6px 0 7px;
	border: 1px solid #b0afb5;
	border-radius: 4px;
	background: #f8f9fa;
}

.opps-instructions {
	margin: 32px -45px 0;
	padding: 32px 45px 45px;
	border-top: 1px solid #b0afb5;
	background: #f8f9fa;
}

ol {
	margin: 17px 0 0 16px;
}

li + li {
	margin-top: 10px;
	color: #000000;
}

.linkOxxo {
	color: #1155cc;
}

.opps-footnote {
	margin-top: 22px;
	padding: 22px 20 24px;
	color: #108f30;
	text-align: center;
	border: 1px solid #108f30;
	border-radius: 4px;
	background: #ffffff;
}
